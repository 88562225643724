export default function Blur({ className, children, dark }) {
  const blur = () => {
    if (dark) return 'bg-blur-dark'
    return 'bg-blur'
  }
  return (
    <div className={ cn('absolute bottom-0 left-0 w-full pt-10 translate-y-1', blur(), className) } >
      { children }
    </div>
  )
}
